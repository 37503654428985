import React from 'react'

function Home() {
    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="text-center">
            <div className="flex items-center justify-center">
              <img
                src="/images/water.webp"
                alt="Water Bottle"
                className="rounded-full "
              />
            </div>
            <h1 className="text-4xl font-bold text-gray-800 mb-4">Under Construction</h1>
            <p className="text-gray-600 mb-8">This site is currently under construction. Check back soon!</p>
          </div>
        </div>
      );
}

export default Home